.header {
  display: flex;
  width: 100%;
  height: 6.38rem;
  margin-top: 0.625rem;
  flex-wrap: nowrap;

  background-color: #396afc0d;
}

.search {
  width: 18.563rem;
  height: 2.313rem;

  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.2rem;
  font-family: "Montserrat";

  color: rgba(2 1 79 0.5);
  border-radius: 0.625rem;
  border: none;

  background: #ffff;
  margin-top: 2.063rem;
  margin-left: 2.5rem;
}
.search:focus {
  outline: none;
}

.dropdown {
  background-color: #ffff;
  width: 9.75rem;
  height: 2.313rem;
  justify-content: center;
  top: 0.625rem;

  border-radius: 0.625rem;
}
.Icon-search {
  position: relative;
  top: 2.75rem;
  left: 4.375rem;

  text-align: center;

  color: #333333;

  margin: 0;
}

.dropbox-container {
  position: relative;
  left: 38.5%;
  top: 1.563rem;
}
.select-section {
  height: 2.313rem;
  text-align: center;
  color: #02014f;
  border: none;
  border-radius: 0.625rem;
  width: 14.375rem;
  cursor: pointer;
  background-color: #ffff;

  margin: 0.625rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
}
.select-orden {
  padding: 0.75rem;
  text-align: center;
  color: #02014f;
  border: none;
  width: 9.375rem;
  background-color: #ffff;

  border-radius: 0.75rem;

  margin: 0.75rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.1rem;
  cursor: pointer;
}
.select-section ::-ms-expand {
  outline: none;
  border: none;
  appearance: none;
  -webkit-appearance: button;

  -moz-border-radius: 0.313rem;
  -webkit-border-radius: 0.313rem;
  border-radius: 0.313rem;
  background-color: #ffff;
}
.select-orden::-ms-expand {
  outline: none;
  border: none;
  appearance: none;
  background-color: #ffff;
  text-align: center;
}

.select-selection option {
  cursor: pointer;
  appearance: none;
}

.select-selection details {
  cursor: pointer;
  appearance: none;
  border: none;
  -webkit-appearance: button;

  -moz-border-radius: 0.313rem;
  -webkit-border-radius: 0.313rem;
  border-radius: 0.313rem;
}
.Icon-orden {
  position: absolute;
  top: 1.313rem;
  margin-left: 0.125rem;
  color: #02014f;
  left: 16.563rem;
}
.Icon-depart {
  color: #02014f;

  position: absolute;
  margin-left: 1rem;
  top: 1.25rem;
}
/* Small devices 576px */
@media (max-width: 35.938rem) {
  .header {
    justify-content: center;
  }
  .dropbox-container {
    display: none;
  }
}
@media (min-width: 578px) and (max-width: 767px) and (orientation: landscape) {
  .header {
    width: 100%;
  }
  .search {
    width: 14.5rem;
    margin-left: 0;
  }
  .dropbox-container {
    left: 8%;
  }
  .select-orden {
    display: none;
  }
  .Icon-orden {
    opacity: 0;
  }
}
@media (min-width: 578px) and (max-width: 767px) and (orientation: portrait) {
  .dropbox-container {
    left: 10%;
  }
  .header {
    width: 100%;
  }
  .select-orden {
    display: none;
  }
  .Icon-orden {
    opacity: 0;
  }
}

/* Medium devices 768px */
@media only screen and (min-width: 48rem) {
  .header {
    width: 100%;
  }
  .dropbox-container {
    left: 4.2%;
  }
}

/* Large devices  992px */
@media only screen and (min-width: 62rem) {
  .header {
    width: 100%;
  }
  .dropbox-container {
    left: 23.5%;
  }
}
/* X-Large devices 1200px */
@media only screen and (min-width: 75rem) {
  .header {
    width: 100%;
  }
  .dropbox-container {
    left: 35%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header {
    width: 100%;
  }

  .select-orden {
    display: none;
  }
  .Icon-orden {
    opacity: 0;
  }
}
