@import url("https://fonts.googleapis.com/css?family=Montserrat");
body {
  margin: 0;
  font-family: "Montserrat";
  width: 100%;
}
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
